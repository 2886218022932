import { useEffect } from 'react'
import { CircularProgress } from '@mui/material'
import axios, { AxiosRequestConfig } from 'axios'
import { Routes, Route, Navigate, Outlet } from 'react-router-dom'

import { Menu } from './layouts'
import {
  Login,
  Users,
  Pets,
  Contests,
  Votes,
  Dashboard,
  Admins,
  CreateContest,
  Achievements,
  BonusVotes
} from './pages'

// Hooks
import { useFetchMyUser } from './services/queries'
import { useAuth } from './services/hooks'

import { PrivateRoute } from './utils/PrivateRoute'

import './App.scss'

axios.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = localStorage.getItem('token')
    if (config?.headers && token) {
      config.headers.Auth = `${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

function App() {
  const { dispatchAuth, isAuthenticated } = useAuth()
  const { data: user, isFetched } = useFetchMyUser()

  // Handle Mobile Screens vh This needs to be done in order to make sure our app is
  // responsive when the user opens the keyboard on mobile screens
  // Basically, I am setting the screen height as var(--vh) whenever
  // the screen size changes
  let vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
  window.addEventListener('resize', () => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  })

  useEffect(() => {
    if (!isFetched) {
      return
    }

    if (!user) {
      return dispatchAuth({ isAuthenticated: false })
    }
    return dispatchAuth({ isAuthenticated: true })
  }, [user, isFetched])

  // Shows a Spinner whenever the application is initializing
  // This will avoid unexpected errors and strange behaviours
  if (isAuthenticated === null) {
    return (
      <div className="main-div">
        <CircularProgress style={{ margin: 'auto' }} size={64} />
      </div>
    )
  }

  return (
    <div className="main-div">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route
            path="/"
            element={
              <>
                <Menu />
                <Outlet />
              </>
            }>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/users" element={<Users />} />
            <Route path="/users/:userID" element={<></>} />
            <Route path="/pets" element={<Pets />} />
            <Route path="/pets/:petID" element={<></>} />
            <Route path="/contests" element={<Contests />} />
            <Route path="/contests/create" element={<CreateContest />} />
            <Route path="/votes" element={<Votes />} />
            <Route path="/bonus-votes" element={<BonusVotes />} />
            <Route path="/admins" element={<Admins />} />
            <Route path="/achievements" element={<Achievements />} />
          </Route>
        </Route>
      </Routes>
    </div>
  )
}

export default App
