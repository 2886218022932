import { TextField } from '@mui/material'

type InputProps = {
  /* the input placeholder */
  placeholder?: string
  /* the current input value */
  value: string
  /* the Dispatch action that is handling the value state */
  setValue: any
  /* if is set to true, the input box is rendered with multiple lines */
  multiline?: boolean
  /* helper text used rendered on the bottom of the input box */
  helperText?: string
  /* helper text used rendered on the bottom of the input box */
  error?: boolean
  onClickEnter?: () => void
  password?: boolean
}

/**
 * Input is used to render a custom input component. This component is used across the whole application.
 */
export const Input = ({
  placeholder,
  value,
  setValue,
  multiline,
  helperText,
  error,
  onClickEnter,
  password
}: InputProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }

  return (
    <TextField
      placeholder={placeholder}
      variant="outlined"
      inputProps={{ style: { fontSize: 14, opacity: 1, color: 'var(--black)' } }}
      multiline={multiline}
      rows={3}
      value={value}
      onChange={handleChange}
      helperText={helperText}
      fullWidth
      type={password ? 'password' : undefined}
      error={value.length > 0 && error}
      onKeyPress={(ev) => {
        if (ev.key === 'Enter' && onClickEnter) {
          onClickEnter()
        }
      }}
      sx={{
        '& .MuiOutlinedInput-root:hover': {
          '& > fieldset': {
            borderColor: 'var(--purple)'
          }
        }
      }}
    />
  )
}
