/* Vote API call queries. Currently using React Query */

import { useQuery } from 'react-query'

import { fetchResolve } from './util'

import { GridVote } from '../../types'

// Query used to get all votes
export function useFetchVotes() {
  return useQuery<GridVote[]>([`useFetchVotes`], async () => fetchResolve(`/votes`))
}
