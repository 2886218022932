import { GridBonusVote } from '../../../types'

export const bonusVotes: GridBonusVote[] = [
  {
    id: 1,
    profile_id: 1,
    profile_name: 'Bernardo Prior',
    profile_picture: '6d1782f2-b7ea-4c30-d534-15f95b463d00',
    profile_email: 'mock@gmail.com',
    contest_name: 'Test Contest',
    contest_place_type: 'NY',
    contest_place: 20,
    created: '2022-10-10T18:49:09.000Z',
    votes: 100,
    used: 0
  },
  {
    id: 2,
    profile_id: 1,
    profile_name: 'Bernardo Prior',
    profile_picture: '6d1782f2-b7ea-4c30-d534-15f95b463d00',
    profile_email: 'mock@gmail.com',
    contest_name: 'Test Contest',
    contest_place_type: 'Global',
    contest_place: 10,
    created: '2022-10-10T18:49:09.000Z',
    votes: 500,
    used: 1
  }
]
