import { Dispatch, useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

/**
 * InputSelect is a customized select input which allows the user to choose from a list of values.
 */
export const InputSelect = ({
  value,
  setValue,
  options,
  placeholder
}: {
  value: string
  setValue: Dispatch<string>
  options: { label: string; value: string }[]
  placeholder: string
}) => {
  const [inputValue, setInputValue] = useState('')

  return (
    <Autocomplete
      disablePortal
      options={options}
      inputValue={inputValue}
      onChange={(event, value) => {
        setValue(value?.value ?? '')
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      isOptionEqualToValue={(option) => option.value === value}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => {
        return <li {...props}>{option.label}</li>
      }}
      renderInput={(params) => <TextField {...params} placeholder={placeholder} />}
    />
  )
}
