import { GridPet } from '../../../types'

export const AllPets: GridPet[] = [
  {
    id: 1,
    pet_name: 'Kaiser',
    pet_type: 'Dog',
    pet_photo_main: '',
    owner_id: 1,
    owner_name: 'Bernardo Prior',
    votes: 1000,
    is_enrolled: true,
    created: new Date().toString()
  }
]
