/* Pet API call queries. Currently using React Query */

import { useQuery } from 'react-query'

import { fetchResolve } from './util'

import { GridPet } from '../../types'

// Query used to get all pets
export function useFetchPets() {
  return useQuery<GridPet[]>([`useFetchPets`], async () => fetchResolve(`/pets`))
}
