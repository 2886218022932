/* Profile API call queries. Currently using React Query */

import { useQuery } from 'react-query'

import { fetchResolve } from './util'

import { GridProfile } from '../../types'

// Query used to get all profiles
export function useFetchProfiles() {
  return useQuery<GridProfile[]>([`useFetchProfiles`], async () => fetchResolve(`/profile`))
}
