import { Typography } from '@mui/material'
import { GridColDef, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid'
import moment from 'moment'

import { Avatar, Loading, Grid } from '../../components'
import { TabHeader } from '../../components'

import { useFetchBonusVotes } from '../../services/queries'

import { getNumberWithOrdinal } from '../../utils/formaters'

import './BonusVotes.scss'

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 120,
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    hide: true,
    renderCell: (params: GridRenderCellParams<string>) => (
      <b style={{ color: `var(--purple)` }}>#{params.value}</b>
    )
  },
  {
    field: 'name',
    headerName: 'NAME',
    minWidth: 250,
    flex: 1,
    renderCell: (params: GridRenderCellParams<string | null>) => (
      <div className="name-cell">
        <Avatar height={32} width={32} imageID={params.row.profile_picture} />
        <div>
          <Typography variant="subtitle1">{params.row.profile_name}</Typography>
          <Typography variant="subtitle2">{params.row.profile_email}</Typography>
        </div>
      </div>
    )
  },
  {
    field: 'created',
    headerName: 'CREATED',
    width: 200,
    type: 'date',
    valueFormatter: (params: GridValueFormatterParams<number>) =>
      moment(params.value).format('DD-MM-YYYY')
  },
  {
    field: 'votes',
    headerName: 'VOTES',
    align: 'left',
    headerAlign: 'left',
    type: 'number',
    width: 200
  },
  {
    field: 'contest_name',
    headerName: 'CONTEST',
    width: 250
  },
  {
    field: 'contest_place',
    headerName: 'PLACE',
    align: 'left',
    headerAlign: 'left',
    type: 'number',
    width: 200,
    renderCell: (params: GridRenderCellParams<string | null>) =>
      `${params.row.contest_place_type} - ${getNumberWithOrdinal(params.row.contest_place)}`
  },
  {
    field: 'used',
    headerName: 'USED',
    type: 'boolean',
    width: 200
  }
]

export const BonusVotes = () => {
  const { data: bonusVotes = [], isLoading } = useFetchBonusVotes()

  return (
    <div className="tab-container bonus-votes-tab">
      <TabHeader title="Bonus Votes" />
      {isLoading ? (
        <Loading />
      ) : (
        <Grid
          rows={bonusVotes}
          columns={columns}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: 'id',
                  sort: 'asc'
                }
              ]
            }
          }}
        />
      )}
    </div>
  )
}
