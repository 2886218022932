import { useEffect } from 'react'
import { QueryClient, QueryCache, QueryClientProvider } from 'react-query'
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles'
import { Toaster } from 'react-hot-toast'
import { BrowserRouter, useLocation } from 'react-router-dom'

import { AuthProvider } from '../services/hooks'

// Create a React query client. We are using react query to
// handle our backend queries. We are not allowing retries whenver
// a request fails and we are providing react query a QueryCache method
// that will cache our Get queries to avoid sending the same request multiple
// times. Please check the documentation for more info.
const queryClient = new QueryClient({
  queryCache: new QueryCache({}),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
      staleTime: 0
    }
  }
})

// THEME MUI
// We are using MuiTheme to make our lives easier when it comes to
// customize text. We are setting a default collor and sizes here.
// Please take a look at MUI docs.
let theme = createTheme({
  palette: {
    primary: {
      main: '#7E73F2'
    },
    secondary: {
      main: '#FEB65E'
    }
  },
  typography: {
    fontFamily: ['Poppins', 'Roboto', 'Helvetica Neue', 'Arial', 'sans-serif'].join(','),
    body1: {
      color: '#5E5873'
    },
    h1: {
      color: '#5E5873',
      fontWeight: 700,
      fontSize: '3.5rem'
    },
    h2: {
      color: '#5E5873',
      fontWeight: 700,
      fontSize: '2.2rem'
    },
    h3: {
      color: '#5E5873',
      fontWeight: 700,
      fontSize: '2rem',
      marginBottom: 24,
      marginTop: 40
    },
    h4: {
      color: '#5E5873',
      fontWeight: 700,
      fontSize: '1.5rem'
    },
    h5: {
      color: '#5E5873',
      fontWeight: 700,
      fontSize: '1.3rem'
    },
    h6: {
      color: '#5E5873',
      fontWeight: 700,
      fontSize: '1rem'
    },
    subtitle1: {
      color: '#6E6B7B',
      fontWeight: 600,
      fontSize: '0.75rem'
    },
    subtitle2: {
      color: '#B9B9C3',
      fontWeight: 400,
      fontSize: '0.75rem'
    },
    body2: {
      color: '#5E5873'
    }
  }
})

// We are using ReponsiveFontSizes as well. This will make
// all the fonts responsive on mobile screens.
theme = responsiveFontSizes(theme)

const ScrollToTop = ({ children }: { children: any }) => {
  const { pathname } = useLocation()
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }, 100)
  }, [pathname])

  return children || null
}

// This component is responsible for wrapping the App component with
// all the hooks needed to run our application. If we ever use jest
// we can use this component to render each component as well.
export const ContextWrapper = ({ children }: { children: any }) => (
  <>
    <BrowserRouter>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <ScrollToTop>{children}</ScrollToTop>
          </ThemeProvider>
        </QueryClientProvider>
      </AuthProvider>
    </BrowserRouter>
    <Toaster position="top-center" reverseOrder={false} />
  </>
)
