import axios from 'axios'

import { CustomError } from '../../types'

// We need this code to make sure that our code is running
// using the mock server locally. In Prod we are using the real
// API url to hit the backend.
let apiURL = 'https://admin-panel-backend-tyyhwntdga-uc.a.run.app/api'
if (['test', 'development'].includes(process.env.NODE_ENV ?? '')) {
  apiURL = 'http://mock.com/api'
}

// This function is called whenever a Get query is perfomed
// If a 4xx or 5xx is received an error is raised. We can catch
// this error using React Query and display the backend error message.
export async function fetchResolve(url: string) {
  try {
    const res = await axios.get(apiURL + url)
    return res.data
  } catch (e: any) {
    const { status, data } = e.response ?? {}
    const newError: CustomError = new Error(e.message) as any
    newError.status = status
    newError.data = data
    throw newError
  }
}

// This function is called whenever a Post query is perfomed
// If a 4xx or 5xx is received an error is raised. We can catch
// this error using React Query and display the backend error message.
export async function postResolve(url: string, body: unknown) {
  try {
    const res = await axios.post(apiURL + url, body)
    return res.data
  } catch (e: any) {
    const { status, data } = e.response ?? {}
    const newError: CustomError = new Error(e.message) as any
    newError.status = status
    newError.data = data
    throw newError
  }
}

// This function is called whenever a Patch query is perfomed
// If a 4xx or 5xx is received an error is raised. We can catch
// this error using React Query and display the backend error message.
export async function patchResolve(url: string, body: unknown) {
  try {
    const res = await axios.patch(apiURL + url, body)
    return res.data
  } catch (e: any) {
    const { status, data } = e.response ?? {}
    const newError: CustomError = new Error(e.message) as any
    newError.status = status
    newError.data = data
    throw newError
  }
}

// This function is called whenever a Delete query is perfomed
// If a 4xx or 5xx is received an error is raised. We can catch
// this error using React Query and display the backend error message.
export async function deleteResolve(url: string) {
  try {
    const res = await axios.delete(apiURL + url)
    return res.data
  } catch (e: any) {
    const { status, data } = e.response ?? {}
    const newError: CustomError = new Error(e.message) as any
    newError.status = status
    newError.data = data
    throw newError
  }
}
