import { GridContest } from '../../../types'

export const AllContests: GridContest[] = [
  {
    id: 1,
    name: 'Test Contest',
    type: 'Dog',
    contestants: 0,
    start_date: '2022-09-06T12:12:49.000Z',
    end_date: '2022-10-06T12:12:49.000Z',
    active: false
  },
  {
    id: 2,
    name: 'Contest To End',
    type: 'Dog',
    contestants: 0,
    start_date: '2022-08-07T12:12:49.000Z',
    end_date: '2022-09-05T12:12:49.000Z',
    active: true
  },
  {
    id: 3,
    name: 'Contest To Delete',
    type: 'Dog',
    contestants: 0,
    start_date: '2023-08-07T12:12:49.000Z',
    end_date: '2022-09-05T12:12:49.000Z',
    active: true
  }
]
