/* BonusVotes API call queries. Currently using React Query */

import { useQuery } from 'react-query'

import { fetchResolve } from './util'

import { GridBonusVote } from '../../types'

export function useFetchBonusVotes() {
  return useQuery<GridBonusVote[]>([`useFetchBonusVotes`], async () => fetchResolve(`/bonus-votes`))
}
