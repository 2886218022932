import { createServer } from 'miragejs'

// Data
import {
  MyUser,
  AllProfiles,
  AllPets,
  AllContests,
  AllVotes,
  AllAdmins,
  achievements,
  achievementTypes,
  bonusVotes
} from './data'

// This function returns a mock server
// A mock server is used to separate all the frontend work from
// the backend work. This way we can mock responses easily everytime
// we need to add a new endpoint. This is really usefull for testing
// purposes as well!
export function makeServer() {
  let server = createServer({
    routes() {
      this.urlPrefix = 'http://mock.com'

      this.post(`/api/auth/login`, () => ({ token: 'token' }))

      this.get(`/api/user/me`, () => MyUser)

      this.get(`/api/profile`, () => AllProfiles)

      this.get(`/api/pets`, () => AllPets)

      this.get(`/api/contests`, () => AllContests)
      this.post(`/api/contests`, () => ({ contest_id: 1 }))
      this.delete(`/api/contests/:id`, () => ({ contest_id: 1 }))

      this.get(`/api/votes`, () => AllVotes)

      this.get(`/api/admins`, () => AllAdmins)

      this.get(`/api/bonus-votes`, () => bonusVotes)

      this.get(`/api/achievements`, () => achievements)
      this.get(`/api/achievements/types`, () => achievementTypes)

      // Image
      this.get(`api/images/direct-upload`, () => 'http://www.image.com')
      this.post(`http://www.image.com`, () => ({
        result: {
          id: '20f68dfe-2144-4a0e-38e8-199a427e6b00'
        }
      }))
    }
  })
  return server
}
