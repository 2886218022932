import './GridChip.scss'

export const GridChip = ({
  value,
  style
}: {
  value: string | number
  style: React.CSSProperties
}) => (
  <div className="grid-chip-div" style={style}>
    {value}
  </div>
)
