/* Admin API call queries. Currently using React Query */

import { useQuery } from 'react-query'

import { fetchResolve } from './util'

import { GridAdmin } from '../../types'

// Query used to get all admins
export function useFetchAdmins() {
  return useQuery<GridAdmin[]>([`useFetchAdmins`], async () => fetchResolve(`/admins`))
}
