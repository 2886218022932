import { Typography } from '@mui/material'
import { IconButton } from '@mui/material'
import { Icon } from '@iconify/react'
import { GridColDef, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

import { Avatar, Loading, Grid, GridChip } from '../../components'
import { TabHeader } from '../../components'

import { useFetchPets } from '../../services/queries'

import './Pets.scss'

export const Pets = () => {
  const navigate = useNavigate()
  const { data: pets = [], isLoading } = useFetchPets()

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 120,
      type: 'number',
      align: 'left',
      headerAlign: 'left',
      hide: true,
      renderCell: (params: GridRenderCellParams<string>) => (
        <b style={{ color: `var(--purple)` }}>#{params.value}</b>
      )
    },
    {
      field: 'pet_name',
      headerName: 'NAME',
      minWidth: 250,
      flex: 1,
      renderCell: (params: GridRenderCellParams<string | null>) => (
        <div className="name-cell">
          <Avatar height={32} width={32} imageID={params.row.pet_photo_main} />
          <div>
            <Typography variant="subtitle1">{params.row.pet_name}</Typography>
            <div className="owner-name-div">
              <Typography variant="subtitle2">{params.row.owner_name}</Typography>
              <button onClick={() => navigate(`/users/${params.row.id}`)}>
                <Icon icon="akar-icons:eye" />
              </button>
            </div>
          </div>
        </div>
      )
    },
    {
      field: 'created',
      headerName: 'CREATED',
      width: 200,
      type: 'date',
      valueFormatter: (params: GridValueFormatterParams<number>) =>
        moment(params.value).format('DD-MM-YYYY')
    },
    {
      field: 'pet_type',
      headerName: 'PET TYPE',
      width: 160,
      renderCell: (params: GridRenderCellParams<string | null>) => (
        <GridChip
          value={params.value ?? 'None'}
          style={{
            color:
              params.value === 'Dog'
                ? '#7E73F2'
                : params.value === 'Cat'
                ? '#FFBC01'
                : params.value === 'Both'
                ? '#28C76F'
                : undefined,
            background:
              params.value === 'Dog'
                ? 'rgba(126, 115, 242, 0.12)'
                : params.value === 'Cat'
                ? 'rgba(255, 188, 1, 0.12)'
                : params.value === 'Both'
                ? 'rgba(40, 199, 111, 0.12)'
                : undefined
          }}
        />
      )
    },
    {
      field: 'is_enrolled',
      headerName: 'ENROLLED',
      type: 'boolean',
      width: 160
    },
    {
      field: 'votes',
      headerName: 'VOTES',
      type: 'number',
      align: 'left',
      headerAlign: 'left',
      width: 110
    },
    {
      field: 'actions',
      headerName: 'ACTIONS',
      width: 160,
      renderCell: (params: GridRenderCellParams<string | null>) => (
        <>
          <IconButton onClick={() => navigate(`/pets/${params.row.id}`)}>
            <Icon icon="akar-icons:eye" />
          </IconButton>
          <IconButton>
            <Icon icon="ep:delete" />
          </IconButton>
        </>
      )
    }
  ]

  return (
    <div className="tab-container pets-tab">
      <TabHeader title="Pets" />
      {isLoading ? (
        <Loading />
      ) : (
        <Grid
          rows={pets}
          columns={columns}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: 'id',
                  sort: 'asc'
                }
              ]
            }
          }}
        />
      )}
    </div>
  )
}
