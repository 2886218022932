/* Achievement API call queries. Currently using React Query */

import { useQuery } from 'react-query'

import { fetchResolve } from './util'

import { GridAchievement } from '../../types'

export function useFetchAchievements() {
  return useQuery<GridAchievement[]>([`useFetchAchievements`], async () =>
    fetchResolve(`/achievements`)
  )
}

export function useFetchAchievementTypes() {
  return useQuery<string[]>([`useFetchAchievementTypes`], async () =>
    fetchResolve(`/achievements/types`)
  )
}
