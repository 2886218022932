import { GridVote } from '../../../types'

export const AllVotes: GridVote[] = [
  {
    id: 1,
    profile_name: 'Bernardo Prior',
    profile_picture:
      'https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=5899346106761662&height=200&width=200&ext=1664731484&hash=AeQdgg2uUfI8WXCEhYw',
    profile_id: 1,
    contest_entry_id: 1,
    contest_id: 1,
    contest_name: 'First Contest',
    created: '2022-09-02T17:25:47.000Z',
    pet_name: 'Kaiser',
    pet_photo_main: 'df6570b2-e877-45eb-121e-2a602f266c00',
    number_votes: 1,
    number_meals: 0,
    price: 0
  },
  {
    id: 2,
    profile_name: 'Joe Rensin',
    profile_picture:
      'https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=5396099840439045&height=200&width=200&ext=1664752185&hash=AeQY8s5IZUCPM5PAJP8',
    profile_id: 2,
    contest_entry_id: 2,
    contest_id: 2,
    contest_name: 'September Contest',
    created: '2022-09-07T17:04:21.000Z',
    pet_name: 'Kaiser',
    pet_photo_main: 'df6570b2-e877-45eb-121e-2a602f266c00',
    number_votes: 100,
    number_meals: 1,
    price: 2
  }
]
