import { Typography } from '@mui/material'
import { IconButton } from '@mui/material'
import moment from 'moment'
import { Icon } from '@iconify/react'
import { GridColDef, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom'

import { TabHeader, Loading, Grid, GridChip, Avatar } from '../../components'

import { useFetchProfiles } from '../../services/queries'

import { ReactComponent as CheckedIcon } from '../../assests/icons/checked.svg'
import { ReactComponent as NotCheckedIcon } from '../../assests/icons/not-checked.svg'

import './Users.scss'

export const Users = () => {
  const navigate = useNavigate()
  const { data: profiles = [], isLoading } = useFetchProfiles()

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 120,
      type: 'number',
      align: 'left',
      headerAlign: 'left',
      hide: true,
      renderCell: (params: GridRenderCellParams<string>) => (
        <b style={{ color: `var(--purple)` }}>#{params.value}</b>
      )
    },
    {
      field: 'name',
      headerName: 'NAME',
      minWidth: 250,
      flex: 1,
      renderCell: (params: GridRenderCellParams<string | null>) => (
        <div className="name-cell">
          <Avatar height={32} width={32} imageID={params.row.profile_picture} />
          <div>
            <Typography variant="subtitle1">{params.row.name}</Typography>
            <div className="email-div">
              <Typography variant="subtitle2">{params.row.email}</Typography>
              {['verified', 'facebook'].includes(params.row?.email_confirmation_status) ? (
                <CheckedIcon />
              ) : (
                <NotCheckedIcon />
              )}
            </div>
          </div>
        </div>
      )
    },
    {
      field: 'created',
      headerName: 'CREATED',
      width: 200,
      type: 'date',
      valueFormatter: (params: GridValueFormatterParams<string>) =>
        moment(params.value).format('DD-MM-YYYY')
    },
    {
      field: 'last_logged_in',
      headerName: 'LAST LOGGED IN',
      width: 200,
      type: 'date',
      valueFormatter: (params: GridValueFormatterParams<number>) =>
        moment(params.value).isValid() ? moment(params.value).format('DD-MM-YYYY hh:mm A') : '--'
    },
    {
      field: 'number_pets',
      headerName: 'NUMBER PETS',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      width: 160
    },
    {
      field: 'pet_type',
      headerName: 'PET TYPE',
      width: 160,
      renderCell: (params: GridRenderCellParams<string | null>) => (
        <GridChip
          value={params.value ?? 'None'}
          style={{
            color:
              params.value === 'Dog'
                ? '#7E73F2'
                : params.value === 'Cat'
                ? '#FFBC01'
                : params.value === 'Both'
                ? '#28C76F'
                : undefined,
            background:
              params.value === 'Dog'
                ? 'rgba(126, 115, 242, 0.12)'
                : params.value === 'Cat'
                ? 'rgba(255, 188, 1, 0.12)'
                : params.value === 'Both'
                ? 'rgba(40, 199, 111, 0.12)'
                : undefined
          }}
        />
      )
    },
    {
      field: 'actions',
      headerName: 'ACTIONS',
      width: 160,
      renderCell: (params: GridRenderCellParams<string | null>) => (
        <>
          <IconButton>
            <Icon icon="akar-icons:edit" />
          </IconButton>
          <IconButton onClick={() => navigate(`/users/${params.row.id}`)}>
            <Icon icon="akar-icons:eye" />
          </IconButton>
          <IconButton>
            <Icon icon="ep:delete" />
          </IconButton>
        </>
      )
    }
  ]

  return (
    <div className="users-tab tab-container">
      <TabHeader title="Users" />
      {isLoading ? (
        <Loading />
      ) : (
        <Grid
          rows={profiles}
          columns={columns}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: 'id',
                  sort: 'asc'
                }
              ]
            }
          }}
        />
      )}
    </div>
  )
}
