/* Contest API call queries. Currently using React Query */

import { useQuery, useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import { fetchResolve, postResolve, deleteResolve } from './util'

import { GridContest, CustomError } from '../../types'

// Query used to get all pets
export function useFetchContests() {
  return useQuery<GridContest[]>([`useFetchContests`], async () => fetchResolve(`/contests`))
}

// This mutation is used to create a new contest
export const useCreateContestMutation = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  return useMutation(
    async ({
      name,
      type,
      startDate,
      endDate,
      image,
      backgroundColor
    }: {
      name: string
      type: string
      startDate: string
      endDate: string
      image: string
      backgroundColor: string
    }) =>
      await postResolve(`/contests`, {
        name,
        type,
        startDate,
        endDate,
        image,
        backgroundColor
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries()
        toast.success('Contest created!')
        navigate('/contests')
      },
      onError: (error: CustomError) => {
        toast.error(error.data?.error?.message ?? 'Internal Server Error!')
      }
    }
  )
}

// This mutation is used to delete a contest
export const useDeleteContest = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  return useMutation(async (contestID: number) => await deleteResolve(`/contests/${contestID}`), {
    onSuccess: () => {
      queryClient.invalidateQueries()
      toast.success('Contest deleted!')
      navigate('/contests')
    },
    onError: (error: CustomError) => {
      toast.error(error.data?.error?.message ?? 'Internal Server Error!')
    }
  })
}
