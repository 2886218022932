/* User API call queries. Currently using React Query */

import { useQuery, useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

// CONTEXT
import { useAuth } from '../hooks'

import { fetchResolve, postResolve } from './util'

import { LoginResponse, CustomError } from '../../types'

// Query used to get current user profile
export function useFetchMyUser() {
  return useQuery<any>([`useFetchMyUser`], async () => fetchResolve(`/user/me`), {
    staleTime: Infinity
  })
}

// This mutation is used to login using username and password
export const useLoginMutation = () => {
  const queryClient = useQueryClient()
  const { dispatchAuth } = useAuth()
  const navigate = useNavigate()

  return useMutation(
    async ({ username, password }: { username: string; password: string }) =>
      await postResolve(`/auth/login`, {
        username,
        password
      }),
    {
      onSuccess: ({ token }: LoginResponse) => {
        localStorage.setItem('token', token)
        dispatchAuth({ isAuthenticated: true })
        queryClient.invalidateQueries()
        navigate('/dashboard')
      },
      onError: (error: CustomError) => {
        toast.error(error.data?.error?.message ?? 'Invalid credentials!')
      }
    }
  )
}
