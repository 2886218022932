import { useState, Dispatch } from 'react'
import { Typography, Popover } from '@mui/material'
import { SketchPicker } from 'react-color'

import './InputColor.scss'

export const InputColor = ({
  label,
  value,
  setValue
}: {
  label: string
  value: string
  setValue: Dispatch<string>
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleChangeComplete = (color: any) => {
    setValue(color.hex)
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <div className="input-color">
      <Typography>{label}:</Typography>
      <button
        className="current-color"
        style={{ backgroundColor: value }}
        onClick={handleClick}></button>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}>
        <SketchPicker color={value} onChangeComplete={handleChangeComplete} />
      </Popover>
    </div>
  )
}
