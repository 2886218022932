import { useState, useEffect } from 'react'
import { Typography } from '@mui/material'
import { Icon } from '@iconify/react'
import { useNavigate, useLocation } from 'react-router-dom'

import './Menu.scss'

// Assets
import { ReactComponent as Logo } from '../../assests/logo.svg'

const MenuItem = ({
  label,
  icon,
  disabled
}: {
  label: string
  icon: string
  disabled?: boolean
}) => {
  const navigate = useNavigate()
  const tab = label.toLowerCase().replace(/ /g, '-')
  const { pathname } = useLocation()
  const [currentTab, setCurrentTab] = useState<string | null>(null)

  useEffect(() => {
    setCurrentTab(pathname.split('/')?.[1] ?? '')
  }, [pathname])

  const handleOnClick = () => {
    navigate(`/${tab}`)
  }

  return (
    <button
      className={`menu-item-bttn ${tab === currentTab ? 'active' : ''}`}
      onClick={handleOnClick}
      disabled={disabled}>
      <Icon icon={icon} />
      <Typography variant="body2">{label}</Typography>
    </button>
  )
}

export const Menu = () => {
  return (
    <menu>
      <Logo className="main-logo" />
      <div className="menu-container">
        <Typography variant="subtitle1">MENU</Typography>
        <div className="menu-items">
          <MenuItem label="Dashboard" icon="ci:home-alt-outline" />
          <MenuItem label="Users" icon="bi:person" />
          <MenuItem label="Pets" icon="cil:cat" />
          <MenuItem label="Contests" icon="charm:stack" />
          <MenuItem label="Votes" icon="ph:paw-print-bold" />
          <MenuItem label="Achievements" icon="bi:check-square" />
          <MenuItem label="Bonus Votes" icon="mdi:package-variant-closed" />
          <MenuItem label="Admins" icon="clarity:group-line" />
        </div>
      </div>
    </menu>
  )
}
