import { useState } from 'react'
import moment from 'moment'

import {
  TabHeader,
  Input,
  InputColor,
  InputSelect,
  InputDate,
  InputImage,
  Button
} from '../../components'

import { useCreateContestMutation } from '../../services/queries'

import './CreateContest.scss'

export const CreateContest = () => {
  const { mutate: createContest } = useCreateContestMutation()
  const [image, setImage] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [type, setType] = useState<string>('')
  const [startDate, setStartDate] = useState<string | null>(null)
  const [endDate, setEndDate] = useState<string | null>(null)
  const [backgroundColor, setBackgroundColor] = useState<string>('#9E95F5')
  const buttonDisabled =
    !image.length ||
    !name.length ||
    !type.length ||
    !moment(startDate).isValid() ||
    !moment(endDate).isValid()

  const handleOnClick = () => {
    if (!buttonDisabled)
      createContest({
        name,
        type,
        startDate: moment(startDate).format('MM/DD/YYYY'),
        endDate: moment(endDate).format('MM/DD/YYYY'),
        image,
        backgroundColor
      })
  }

  console.log(backgroundColor)

  return (
    <div className="tab-container create-contest">
      <TabHeader title="Create Contest" />
      <div className="form-wrapper">
        <div className="input-container">
          <InputImage value={image} setValue={setImage} />
          <InputColor
            label="Background Color"
            value={backgroundColor}
            setValue={setBackgroundColor}
          />
          <Input placeholder="Name" value={name} setValue={setName} />
          <InputSelect
            placeholder="Type"
            value={type}
            setValue={setType}
            options={[
              { label: 'Dog', value: 'Dog' },
              { label: 'Cat', value: 'Cat' }
            ]}
          />
          <InputDate
            placeholder="Start Date (mm/dd/YYYY)"
            value={startDate}
            setValue={setStartDate}
          />
          <InputDate placeholder="End Date (mm/dd/YYYY)" value={endDate} setValue={setEndDate} />
        </div>
        <Button text="Create" onClick={handleOnClick} disabled={buttonDisabled} />
      </div>
    </div>
  )
}
