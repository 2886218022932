import { useState, Dispatch } from 'react'
import { toast } from 'react-hot-toast'
import { CircularProgress } from '@mui/material'

import { ReactComponent as AddPhotoIcon } from '../../assests/add-photo.svg'

import { fetchResolve } from '../../services/queries'
import { FormatImageURL } from '../../utils/FormatImageURL'

import './InputImage.scss'

export const InputImage = ({ value, setValue }: { value: string; setValue: Dispatch<string> }) => {
  const [isLoadingUpload, setIsLoadingUpload] = useState(false)

  const onEdit = async (e: any) => {
    try {
      setIsLoadingUpload(true)
      const file = e.target.files[0]

      if (!file) {
        return
      }
      if (file.size > 40000000) {
        toast.error('File should be smaller than 4MB')
        return
      }
      const uploadURL = await fetchResolve(`/images/direct-upload`)

      let formData = new FormData()
      formData.append('file', file)

      // Upload value
      const res = await fetch(uploadURL, {
        method: 'POST',
        body: formData
      })
      const { result } = await res.json()

      setValue(result.id)
    } catch (e) {
      toast.error('Error uploading image. Try again!')
    } finally {
      setIsLoadingUpload(false)
    }
  }

  return (
    <div className="input-image">
      {isLoadingUpload ? (
        <CircularProgress size={48} />
      ) : value !== '' ? (
        <>
          <label htmlFor="file-upload" className="edit-button">
            <img src={FormatImageURL(value, 'card')} alt="Photo" className="photo" />
          </label>
          <input id="file-upload" data-testid="file-upload" type="file" onChange={onEdit} />
        </>
      ) : (
        <>
          <label htmlFor="file-upload" className="edit-button">
            <AddPhotoIcon />
          </label>
          <input id="file-upload" data-testid="file-upload" type="file" onChange={onEdit} />
        </>
      )}
    </div>
  )
}
