import { useMemo } from 'react'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { Typography, Checkbox } from '@mui/material'

import { TabHeader, Loading, Grid, Avatar } from '../../components'

import { useFetchAchievements, useFetchAchievementTypes } from '../../services/queries'

import './Achievements.scss'

const columns: GridColDef[] = [
  {
    field: 'profile_name',
    headerName: 'NAME',
    minWidth: 250,
    flex: 1,
    renderCell: (params: GridRenderCellParams<string | null>) => (
      <div className="name-cell">
        <Avatar height={32} width={32} imageID={params.row.profile_picture} />
        <div>
          <Typography variant="subtitle1">{params.row.profile_name}</Typography>
          <Typography variant="subtitle2">{params.row.profile_email}</Typography>
        </div>
      </div>
    )
  }
]

export const Achievements = () => {
  const { data: achievements = [], isLoading: isLoadingAchievements } = useFetchAchievements()
  const { data: types = [], isLoading: isLoadingTypes } = useFetchAchievementTypes()

  const achievementColumns = useMemo(() => {
    const aux = [...columns]
    for (const type of types) {
      aux.push({
        headerAlign: 'center',
        align: 'center',
        field: type,
        headerName: type,
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<string | null>) => {
          const item = params.row.achievements.find((a: any) => a.title === params.field)
          return (
            <Checkbox
              checked={!!item}
              sx={{
                color: !item?.used ? 'gray' : 'var(--purple)',
                '&.Mui-checked': {
                  color: !item?.used ? 'gray' : 'var(--purple)'
                }
              }}
              size="small"
            />
          )
        }
      })
    }
    return aux
  }, [types])

  return (
    <div className="tab-container achievements-tab">
      <TabHeader title="Achievements" />
      {isLoadingAchievements || isLoadingTypes ? (
        <Loading />
      ) : (
        <Grid
          rows={achievements.map((a) => ({ id: a.profile_id, ...a }))}
          columns={achievementColumns}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: 'profile_id',
                  sort: 'asc'
                }
              ]
            }
          }}
        />
      )}
    </div>
  )
}
