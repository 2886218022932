import { Typography } from '@mui/material'

import './TabHeader.scss'

export const TabHeader = ({ title, children }: { title: string; children?: any }) => (
  <div className="tab-header">
    <Typography variant="h4">{title}</Typography>
    {children}
  </div>
)
