import { IconButton } from '@mui/material'
import { Icon } from '@iconify/react'
import { GridColDef, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

import { Button, Loading, Grid, GridChip, TabHeader } from '../../components'

import { useFetchContests, useDeleteContest } from '../../services/queries'

import './Contests.scss'

export const Contests = () => {
  const navigate = useNavigate()
  const { data: contests = [], isLoading } = useFetchContests()
  const { mutate: deleteContest } = useDeleteContest()

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 120,
      type: 'number',
      align: 'left',
      headerAlign: 'left',
      hide: true,
      renderCell: (params: GridRenderCellParams<string>) => (
        <b style={{ color: `var(--purple)` }}>#{params.value}</b>
      )
    },
    {
      field: 'name',
      headerName: 'NAME',
      minWidth: 250,
      flex: 1
    },
    {
      field: 'type',
      headerName: 'CONTEST TYPE',
      width: 160,
      renderCell: (params: GridRenderCellParams<string | null>) => (
        <GridChip
          value={params.value ?? 'None'}
          style={{
            color:
              params.value === 'Dog'
                ? '#7E73F2'
                : params.value === 'Cat'
                ? '#FFBC01'
                : params.value === 'Both'
                ? '#28C76F'
                : undefined,
            background:
              params.value === 'Dog'
                ? 'rgba(126, 115, 242, 0.12)'
                : params.value === 'Cat'
                ? 'rgba(255, 188, 1, 0.12)'
                : params.value === 'Both'
                ? 'rgba(40, 199, 111, 0.12)'
                : undefined
          }}
        />
      )
    },
    {
      field: 'contestants',
      headerName: 'CONTESTANTS',
      width: 180,
      type: 'number',
      align: 'left',
      headerAlign: 'left'
    },
    {
      field: 'start_date',
      headerName: 'START DATE',
      width: 200,
      type: 'date',
      valueFormatter: (params: GridValueFormatterParams<number>) =>
        moment(params.value).format('DD-MM-YYYY hh:mm A')
    },
    {
      field: 'end_date',
      headerName: 'END DATE',
      width: 200,
      type: 'date',
      valueFormatter: (params: GridValueFormatterParams<number>) =>
        moment(params.value).format('DD-MM-YYYY hh:mm A')
    },
    {
      field: 'active',
      headerName: 'ACTIVE',
      width: 200,
      type: 'boolean'
    },
    {
      field: 'actions',
      headerName: 'ACTIONS',
      width: 160,
      renderCell: (params: GridRenderCellParams<string | null>) => (
        <>
          <IconButton onClick={() => navigate(`/contests/${params.row.id}`)}>
            <Icon icon="akar-icons:eye" />
          </IconButton>
          {params.row.contestants <= 5 && (
            <IconButton onClick={() => handleOnClickDelete(params.row.id)}>
              <Icon icon="ep:delete" />
            </IconButton>
          )}
        </>
      )
    }
  ]

  const handleOnClickDelete = (contestID: number) => {
    deleteContest(contestID)
  }

  return (
    <div className="tab-container contests-tab">
      <TabHeader title="Contests">
        <Button text="New" onClick={() => navigate('/contests/create')} style={{ width: 100 }} />
      </TabHeader>
      {isLoading ? (
        <Loading />
      ) : (
        <Grid
          rows={contests}
          columns={columns}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: 'id',
                  sort: 'asc'
                }
              ]
            }
          }}
        />
      )}
    </div>
  )
}
