import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport
} from '@mui/x-data-grid'

import './Grid.scss'

const CustomToolbar = () => (
  <GridToolbarContainer>
    <GridToolbarColumnsButton />
    <GridToolbarFilterButton />
    <GridToolbarExport />
  </GridToolbarContainer>
)

export const Grid = ({
  columns,
  rows,
  initialState
}: {
  columns: GridColDef[]
  rows: any[]
  initialState?: any
}) => {
  return (
    <DataGrid
      style={{ height: '100%', marginBottom: 32 }}
      rows={rows}
      columns={columns}
      disableSelectionOnClick
      initialState={initialState}
      components={{
        Toolbar: CustomToolbar
      }}
      sortingOrder={['desc', 'asc']}
    />
  )
}
