import { GridProfile } from '../../../types'

export const AllProfiles: GridProfile[] = [
  {
    id: 1,
    name: 'Bernardo Prior',
    profile_picture: '6d1782f2-b7ea-4c30-d534-15f95b463d00',
    email: 'mock@gmail.com',
    created: '2022-09-06T12:12:49.000Z',
    number_pets: 2,
    pet_type: 'Dog',
    last_logged_in: '2022-09-06T12:12:49.000Z',
    email_confirmation_status: 'verified'
  }
]
