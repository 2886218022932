import { Typography, CircularProgress } from '@mui/material'

import './Button.scss'

/**
 * Button is used across all the application. All the main button are rendered using this component. This component is fully customizable but should be used without providing
 * any style to it whenever possible.
 */
export const Button = ({
  text,
  onClick,
  icon,
  color = 'purple',
  disabled,
  style,
  isLoading,
  bold
}: {
  /** Text rendered inside the button */
  text: string
  /** onClick action */
  onClick?: () => void
  /**  if we want to display an icon on the left of the text rendered, we can pass it using this prop */
  icon?: any
  /** button color */
  color?: string
  /** if true, the button will be set to disabled */
  disabled?: boolean
  /** if we want to add extra style, we can use this prop */
  style?: any
  /** shows a spinner whenever is set to true */
  isLoading?: boolean
  /** makes the text bold */
  bold?: boolean
}) => {
  return (
    <button
      className={`main-bttn ${color}`}
      onClick={onClick}
      disabled={disabled || isLoading}
      style={style}>
      {icon && <>{icon}</>}
      <Typography>{bold ? <b>{text}</b> : text}</Typography>
      {isLoading && <CircularProgress size={12} />}
    </button>
  )
}
