import { Typography } from '@mui/material'
import { GridColDef, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid'
import moment from 'moment'

import { TabHeader, Loading, Grid, Avatar } from '../../components'

import { useFetchVotes } from '../../services/queries'

import './Votes.scss'

export const Votes = () => {
  const { data: votes = [], isLoading } = useFetchVotes()

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 120,
      type: 'number',
      align: 'left',
      headerAlign: 'left',
      hide: true,
      renderCell: (params: GridRenderCellParams<string>) => (
        <b style={{ color: `var(--purple)` }}>#{params.value}</b>
      )
    },
    {
      field: 'name',
      headerName: 'NAME',
      minWidth: 250,
      flex: 1,
      renderCell: (params: GridRenderCellParams<string | null>) => (
        <div className="name-cell">
          <Avatar height={32} width={32} imageID={params.row.profile_picture} />
          <Typography variant="subtitle1">{params.row.profile_name}</Typography>
        </div>
      )
    },
    {
      field: 'pet_name',
      headerName: 'PET',
      minWidth: 200,
      flex: 1,
      renderCell: (params: GridRenderCellParams<string | null>) => (
        <div className="name-cell">
          <Avatar height={32} width={32} imageID={params.row.pet_photo_main} />
          <Typography variant="subtitle1">{params.row.pet_name}</Typography>
        </div>
      )
    },
    {
      field: 'contest_name',
      headerName: 'CONTEST',
      width: 220
    },
    {
      field: 'price',
      headerName: 'PRICE',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      width: 150,
      valueFormatter: (params: GridValueFormatterParams<number>) => `$${params.value}`
    },
    {
      field: 'number_votes',
      headerName: 'VOTES',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      width: 200
    },
    {
      field: 'number_meals',
      headerName: 'MEALS',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      width: 200
    },
    {
      field: 'created',
      headerName: 'CREATED',
      width: 200,
      type: 'date',
      valueFormatter: (params: GridValueFormatterParams<number>) =>
        moment(params.value).format('DD-MM-YYYY hh:mm A')
    }
  ]

  return (
    <div className="tab-container votes-tab">
      <TabHeader title="Votes" />
      {isLoading ? (
        <Loading />
      ) : (
        <Grid
          rows={votes}
          columns={columns}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: 'created',
                  sort: 'desc'
                }
              ]
            }
          }}
        />
      )}
    </div>
  )
}
