import { IconButton } from '@mui/material'
import { Icon } from '@iconify/react'
import { GridColDef, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid'
import moment from 'moment'

import { Loading, Grid, TabHeader } from '../../components'

import { useFetchAdmins } from '../../services/queries'

export const Admins = () => {
  const { data: admins = [], isLoading } = useFetchAdmins()

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 120,
      type: 'number',
      align: 'left',
      headerAlign: 'left',
      hide: true,
      renderCell: (params: GridRenderCellParams<string>) => (
        <b style={{ color: `var(--purple)` }}>#{params.value}</b>
      )
    },
    {
      field: 'name',
      headerName: 'NAME',
      minWidth: 250,
      flex: 1
    },
    {
      field: 'status',
      headerName: 'STATUS',
      width: 200
    },
    {
      field: 'created',
      headerName: 'CREATED',
      width: 200,
      type: 'date',
      valueFormatter: (params: GridValueFormatterParams<number>) =>
        moment(params.value).format('DD-MM-YYYY hh:mm A')
    },
    {
      field: 'actions',
      headerName: 'ACTIONS',
      width: 160,
      renderCell: () => (
        <>
          <IconButton>
            <Icon icon="ep:delete" />
          </IconButton>
        </>
      )
    }
  ]

  return (
    <div className="tab-container admins-tab">
      <TabHeader title="Admins" />
      {isLoading ? (
        <Loading />
      ) : (
        <Grid
          rows={admins}
          columns={columns}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: 'id',
                  sort: 'asc'
                }
              ]
            }
          }}
        />
      )}
    </div>
  )
}
