import { useState } from 'react'
import { Typography } from '@mui/material'

import { Input, Button } from '../../components'

import { useLoginMutation } from '../../services/queries'

import './Login.scss'

export const Login = () => {
  const { mutate, isLoading } = useLoginMutation()
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const isButtonDisabled = !username.length || !password.length

  const handleOnClick = () => {
    if (!isButtonDisabled) {
      mutate({ username, password })
    }
  }

  return (
    <div className="login-page">
      <div className="login-modal">
        <Typography variant="h5">Login</Typography>
        <div className="form-container">
          <Input
            placeholder="Username"
            value={username}
            setValue={setUsername}
            onClickEnter={handleOnClick}
          />
          <Input
            placeholder="Password"
            value={password}
            setValue={setPassword}
            password
            onClickEnter={handleOnClick}
          />
          <Button
            text="Continue"
            onClick={handleOnClick}
            disabled={isButtonDisabled}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  )
}
