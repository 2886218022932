// Auth Context. Is used to store if the User is authenticated or not.
// This context is used to protect private routes as well as hidding some
// components that will only be rendered if the user is logged in.

import { createContext, useContext, useReducer } from 'react'

type TAction = {
  isAuthenticated?: boolean | null
}
type AuthProviderProps = { children: React.ReactNode; options?: any }
interface IState {
  isAuthenticated: boolean | null
}
interface IContext extends IState {
  dispatchAuth: any
}

const AuthContext = createContext<IContext | undefined>(undefined)

const initialStateAuth = {
  isAuthenticated: null as any
}

function AuthReducer(state = initialStateAuth, action: TAction) {
  return { ...state, ...action }
}

function AuthProvider({ children, options = {} }: AuthProviderProps) {
  const [stateAuth, dispatchAuth] = useReducer(AuthReducer, {
    ...initialStateAuth,
    ...options
  })
  const value = { ...stateAuth, dispatchAuth }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

function useAuth() {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a IDProvider`)
  }
  return context
}
export { initialStateAuth, AuthProvider, useAuth }
