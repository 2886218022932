import { GridAchievement } from '../../../types'

export const achievements: GridAchievement[] = [
  {
    profile_id: 1,
    profile_name: 'Bernardo Prior',
    profile_picture:
      'https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=5899346106761662&height=200&width=200&ext=1666352846&hash=AeQisKvcUa90_swbLrM',
    profile_email: 'bernardo@gmail.com',
    achievements: [
      { used: null, title: 'All you need is dog', created: '2022-09-21 10:28:14.000000' },
      { used: null, title: 'An incredible story', created: '2022-09-21 10:28:14.000000' },
      { used: null, title: 'Life is better with a Cat', created: '2022-09-21 12:15:46.000000' },
      {
        used: null,
        title: 'Journey starts with a single step',
        created: '2022-09-21 12:26:38.000000'
      },
      { used: null, title: '#Monday', created: '2022-09-26 10:31:48.000000' }
    ]
  }
]

export const achievementTypes: string[] = [
  'An incredible story',
  'Journey starts with a single step',
  'True fan',
  'True follower',
  'All you need is dog',
  'Life is better with a Cat',
  '#Monday'
]
