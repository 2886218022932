import NoAvatar from '../../assests/no-avatar.png'

import { Image } from '..'

import { FormatImageURL } from '../../utils/FormatImageURL'

import './Avatar.scss'

/**
 * This component is used across all the pages to render the user's avatar. It is going to use the 'square' version
 * of the image provided. It's important to make sure that imageID is a valid Cloudflare image.
 * ImageID is the unique identifier returned by Cloudflare Images Api
 * Height and Width are the dimensions of the rendered image.
 * If no imageID is provided, the NoAvatar image will be rendered.
 */
export const Avatar = ({
  imageID,
  height = 50,
  width = 50
}: {
  /** ImageID is the unique identifier returned by Cloudflare Images Api */
  imageID: string
  /** Image height */
  height?: number
  /** Image width */
  width?: number
}) => {
  return (
    <Image
      className="avatar"
      style={{ height, width }}
      src={`${
        imageID.length
          ? imageID.includes('http')
            ? imageID
            : FormatImageURL(imageID, 'square')
          : NoAvatar
      }`}
      alt="avatar"
    />
  )
}
